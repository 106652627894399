import Store from "@/store/index.js";
import { getRequest, postRequest } from "./endpoint";

// 処理識別文字列
const sUser = Store.state.userCode;
const sAccess = "WEB";

//****************************************************************
// 接続チェック
//****************************************************************
// サーバーチェック
export async function getCheckServer() {
  const params = {};
  return await getRequest("/auth/server", params);
}
// データベースチェック
export async function getCheckDB() {
  const params = {};
  return await getRequest("/auth/db", params);
}

//****************************************************************
// ログイン
//****************************************************************
// ログイン（パスワード認証）
export async function postAuthLogin(KigyoCd, Login, Password) {
  const params = new URLSearchParams();
  params.append("KigyoCd", KigyoCd);
  params.append("Login", Login);
  params.append("Password", Password);
  params.append("sAccess", sAccess);
  return await postRequest("/auth/login", params);
}
// パスワードリセット
export async function postResetPass(KigyoCd, UserCd, NewPass) {
  const params = new URLSearchParams();
  params.append("Password", NewPass);
  params.append("sUser", UserCd);
  params.append("sAccess", sAccess);
  params.append("KigyoCd", KigyoCd);
  params.append("UserCd", UserCd);
  params.append("sAccess", sAccess);
  return await postRequest("/auth/pass", params);
}

//****************************************************************
// 弁当マスタ
//****************************************************************
// 弁当一覧取得
export async function getBentoList(KigyoCd, JigyoNo, GyoshaNo) {
  const params = {
    KigyoCd: KigyoCd,
    JigyoNo: JigyoNo,
    GyoshaNo: GyoshaNo,
  };
  return await getRequest("/m_bento_get/bento_list", params);
}
// 弁当情報取得
export async function getBentoInfo(KigyoCd, JigyoNo, GyoshaNo, BentoNo) {
  const params = {
    KigyoCd: KigyoCd,
    JigyoNo: JigyoNo,
    GyoshaNo: GyoshaNo,
    BentoNo: BentoNo,
  };
  return await getRequest("/m_bento_get/bento_info", params);
}
export async function getBentoListJigyo(KigyoCd, JigyoNo) {
  const params = {
    KigyoCd: KigyoCd,
    JigyoNo: JigyoNo,
  };
  return await getRequest("/m_bento_get/bento_list_jigyo", params);
}
export async function postBentoInsert(KigyoCd, GyoshaNo, JigyoNo, BentoName, BentoKingaku, BentoCalory) {
  const params = new URLSearchParams();
  params.append("KigyoCd", KigyoCd);
  params.append("GyoshaNo", GyoshaNo);
  params.append("JigyoNo", JigyoNo);
  params.append("BentoName", BentoName);
  params.append("BentoKingaku", BentoKingaku);
  params.append("BentoCalory", BentoCalory);
  params.append("sUser", sUser);
  params.append("sAccess", sAccess);
  return await postRequest("/m_bento_post/insert", params);
}
export async function postBentoUpdate(KigyoCd, JigyoNo, GyoshaNo, BentoNo, BentoName, BentoKingaku, BentoCalory, BentoImg) {
  const params = new URLSearchParams();
  params.append("KigyoNo", KigyoCd);
  params.append("JigyoNo", JigyoNo);
  params.append("GyoshaNo", GyoshaNo);
  params.append("BentoNo", BentoNo);
  params.append("BentoName", BentoName);
  params.append("BentoKingaku", BentoKingaku);
  params.append("BentoCalory", BentoCalory);
  params.append("BentoImg", BentoImg);
  params.append("sUser", sUser);
  params.append("sAccess", sAccess);
  return await postRequest("/m_bento_post/update", params);
}
export async function postBentoDelete(KigyoCd, JigyoNo, GyoshaNo, BentoNo) {
  const params = new URLSearchParams();
  params.append("KigyoNo", KigyoCd);
  params.append("JigyoNo", JigyoNo);
  params.append("GyoshaNo", GyoshaNo);
  params.append("BentoNo", BentoNo);
  params.append("sUser", sUser);
  params.append("sAccess", sAccess);
  return await postRequest("/m_bento_post/delete", params);
}

//****************************************************************
// 部署マスタ
//****************************************************************
export async function getBushoList(KigyoCd) {
  const params = {
    KigyoCd: KigyoCd,
  };
  return await getRequest("/m_busho_get/busho_list", params);
}
export async function postBushoInsert(KigyoCd, BushoName, Tel, Naisen) {
  const params = new URLSearchParams();
  params.append("KigyoCd", KigyoCd);
  params.append("BushoName", BushoName);
  params.append("Tel", Tel);
  params.append("Naisen", Naisen);
  params.append("sUser", sUser);
  params.append("sAccess", sAccess);
  return await postRequest("/m_busho_post/insert", params);
}
export async function postBushoUpdate(KigyoCd, BushoNo, BushoName, Tel, Naisen) {
  const params = new URLSearchParams();
  params.append("KigyoCd", KigyoCd);
  params.append("BushoNo", BushoNo);
  params.append("BushoName", BushoName);
  params.append("Tel", Tel);
  params.append("Naisen", Naisen);
  params.append("sUser", sUser);
  params.append("sAccess", sAccess);
  return await postRequest("/m_busho_post/update", params);
}
export async function postBushoDelete(KigyoCd, BushoNo) {
  const params = new URLSearchParams();
  params.append("KigyoCd", KigyoCd);
  params.append("BushoNo", BushoNo);
  params.append("sUser", sUser);
  params.append("sAccess", sAccess);
  return await postRequest("/m_busho_post/delete", params);
}

//****************************************************************
// 弁当業者マスタ
//****************************************************************
export async function getGyoshaList(KigyoCd, JigyoNo) {
  const params = {
    KigyoCd: KigyoCd,
    JigyoNo: JigyoNo,
  };
  return await getRequest("/m_gyosha_get/gyosha_list", params);
}
//----------------------------------------------------------------
// 弁当業者一覧取得（すべて）
//----------------------------------------------------------------
export async function getGyoshaList2(KigyoCd) {
  const params = {
    KigyoCd: KigyoCd,
  };
  return await getRequest("/m_gyosha_get/gyosha_list2", params);
}
export async function getGyoshaInfo(KigyoCd, JigyoNo, GyoshaNo) {
  const params = {
    KigyoCd: KigyoCd,
    JigyoNo: JigyoNo,
    GyoshaNo: GyoshaNo,
  };
  return await getRequest("/m_gyosha_get/gyosha_info", params);
}
//----------------------------------------------------------------
// 弁当業者登録
//----------------------------------------------------------------
export async function postGyoshaInsert(KigyoCd, JigyoNo, GyoshaName, GyoshaKana, ChuKbn, Tel1, Tel2, Fax, Mail, Simetime, MailSendTime, Post, Address, AutoFlg, NaiFlg) {
  const params = new URLSearchParams();
  params.append("KigyoCd", KigyoCd);
  params.append("JigyoNo", JigyoNo);
  params.append("GyoshaName", GyoshaName);
  params.append("GyoshaKana", GyoshaKana);
  params.append("ChuKbn", ChuKbn);
  params.append("Tel1", Tel1);
  params.append("Tel2", Tel2);
  params.append("Fax", Fax);
  params.append("Mail", Mail);
  params.append("Simetime", Simetime);
  params.append("MailSendTime", MailSendTime);
  params.append("Post", Post);
  params.append("Address", Address);
  params.append("AutoFlg", AutoFlg);
  params.append("NaiFlg", NaiFlg);
  params.append("sUser", sUser);
  params.append("sAccess", sAccess);
  return await postRequest("/m_gyosha_post/insert", params);
}
//----------------------------------------------------------------
// 弁当業者更新
//----------------------------------------------------------------
export async function postGyoshaUpdate(KigyoNo, JigyoNo, maxGyosha, GyoshaName, GyoshaKana, ChuKbn, Tel1, Tel2, Fax, Mail, Simetime, MailSendTime, Post, Address, AutoFlg, NaiFlg) {
  const params = new URLSearchParams();
  params.append("KigyoNo", KigyoNo);
  params.append("JigyoNo", JigyoNo);
  params.append("maxGyosha", maxGyosha);
  params.append("GyoshaName", GyoshaName);
  params.append("GyoshaKana", GyoshaKana);
  params.append("ChuKbn", ChuKbn);
  params.append("Tel1", Tel1);
  params.append("Tel2", Tel2);
  params.append("Fax", Fax);
  params.append("Mail", Mail);
  params.append("Simetime", Simetime);
  params.append("MailSendTime", MailSendTime);
  params.append("Post", Post);
  params.append("Address", Address);
  params.append("AutoFlg", AutoFlg);
  params.append("NaiFlg", NaiFlg);
  params.append("sUser", sUser);
  params.append("sAccess", sAccess);
  return await postRequest("/m_gyosha_post/update", params);
}
//----------------------------------------------------------------
// 弁当業者削除
//----------------------------------------------------------------
export async function postGyoshaDelete(KigyoNo, JigyoNo, GyoshaNo) {
  const params = new URLSearchParams();
  params.append("KigyoNo", KigyoNo);
  params.append("JigyoNo", JigyoNo);
  params.append("GyoshaNo", GyoshaNo);
  params.append("sUser", sUser);
  params.append("sAccess", sAccess);
  return await postRequest("/m_gyosha_post/delete", params);
}

//****************************************************************
// 事業所マスタ
//****************************************************************
//----------------------------------------------------------------
// 事業所一覧取得
//----------------------------------------------------------------
export async function getJigyoList(KigyoCd) {
  const params = {
    KigyoCd: KigyoCd,
  };
  return await getRequest("/m_jigyo_get/jigyosyo_list", params);
}
// export async function getJigyoList2(KigyoCd) {
//   const params = {
//     KigyoCd: KigyoCd,
//   };
//   return await getRequest("/m_jigyo_get/jigyosyo_select", params);
// }
export async function getJigyoFax(KigyoCd) {
  const params = {
    KigyoCd: KigyoCd,
  };
  return await getRequest("/m_jigyo_get/jigyosyo_fax", params);
}
export async function getJigyoSample(KigyoCd) {
  const params = {
    KigyoCd: KigyoCd,
  };
  return await getRequest("/m_jigyo_get/jigyosyo_sample", params);
}
//----------------------------------------------------------------
// 事業所登録
//----------------------------------------------------------------
export async function postJigyoInsert(KigyoCd, JigyoName, JigyoKana, Address, Tel1, Tel2, Fax, Post, Naisen, Mail) {
  const params = new URLSearchParams();
  params.append("KigyoCd", KigyoCd);
  params.append("JigyoName", JigyoName);
  params.append("JigyoKana", JigyoKana);
  params.append("Address", Address);
  params.append("Tel1", Tel1);
  params.append("Tel2", Tel2);
  params.append("Fax", Fax);
  params.append("Post", Post);
  params.append("Naisen", Naisen);
  params.append("Mail", Mail);
  params.append("sUser", sUser);
  params.append("sAccess", sAccess);
  return await postRequest("/m_jigyo_post/insert", params);
}
//----------------------------------------------------------------
// 事業所更新
//----------------------------------------------------------------
export async function postJigyoUpdate(KigyoCd, JigyoNo, JigyoName, JigyoKana, Address, Tel1, Tel2, Fax, Post, Naisen, Mail) {
  const params = new URLSearchParams();
  params.append("KigyoCd", KigyoCd);
  params.append("JigyoNo", JigyoNo);
  params.append("JigyoName", JigyoName);
  params.append("JigyoKana", JigyoKana);
  params.append("Address", Address);
  params.append("Tel1", Tel1);
  params.append("Tel2", Tel2);
  params.append("Fax", Fax);
  params.append("Post", Post);
  params.append("Naisen", Naisen);
  params.append("Mail", Mail);
  params.append("sUser", sUser);
  params.append("sAccess", sAccess);
  return await postRequest("/m_jigyo_post/update", params);
}
//----------------------------------------------------------------
// 事業所削除
//----------------------------------------------------------------
export async function postJigyoDelete(KigyoCd, JigyoNo) {
  const params = new URLSearchParams();
  params.append("KigyoCd", KigyoCd);
  params.append("JigyoNo", JigyoNo);
  params.append("sUser", sUser);
  params.append("sAccess", sAccess);
  return await postRequest("/m_jigyo_post/delete", params);
}

//****************************************************************
// 企業マスタ
//****************************************************************
export async function getKigyoList() {
  const params = {};
  return await getRequest("/m_kigyo_get/list", params);
}
export async function getKigyoInfo(KigyoCd) {
  const params = {
    KigyoCd: KigyoCd,
  };
  return await getRequest("/m_kigyo_get/info", params);
}
export async function getKigyoExist(KigyoCd) {
  const params = {
    KigyoCd: KigyoCd,
  };
  return await getRequest("/m_kigyo_get/exist", params);
}
export async function postKigyoInsert(KigyoCd) {
  const params = new URLSearchParams();
  params.append("KigyoCd", KigyoCd);
  return await postRequest("/m_kigyo_post/insert", params);
}
export async function postKigyoUpdate(KigyoCd) {
  const params = new URLSearchParams();
  params.append("KigyoCd", KigyoCd);
  return await postRequest("/m_kigyo_post/update", params);
}
export async function postKigyoDelete(KigyoCd) {
  const params = new URLSearchParams();
  params.append("KigyoCd", KigyoCd);
  return await postRequest("/m_kigyo_post/delete", params);
}

//****************************************************************
// 献立マスタ
//****************************************************************
export async function getKondateImage(KigyoCd, Nengetu) {
  const params = {
    KigyoCd: KigyoCd,
    Nengetu: Nengetu,
  };
  return await getRequest("/m_kondate_get/image", params);
}
export async function postKondateInsert(KigyoCd) {
  const params = new URLSearchParams();
  params.append("KigyoCd", KigyoCd);
  return await postRequest("/m_kondate_post/insert", params);
}

//****************************************************************
// メール定型文マスタ
//****************************************************************
export async function getMailtmpTeikei(KigyoCd) {
  const params = {
    KigyoCd: KigyoCd,
  };
  return await getRequest("/m_mailtmp_get/teikei_get", params);
}
export async function postMailtmpUpdate(KigyoCd) {
  const params = new URLSearchParams();
  params.append("KigyoCd", KigyoCd);
  return await postRequest("/m_mailtmp_post/update", params);
}

//****************************************************************
// 利用者マスタ
//****************************************************************
export async function getUserRiyoList(KigyoCd) {
  const params = {
    KigyoCd: KigyoCd,
  };
  return await getRequest("/m_user_get/riyo_list", params);
}
export async function getUserCheckCode(KigyoCd) {
  const params = {
    KigyoCd: KigyoCd,
  };
  return await getRequest("/m_user_get/checkcode", params);
}
export async function getUserCheckId(KigyoCd) {
  const params = {
    KigyoCd: KigyoCd,
  };
  return await getRequest("/m_user_get/checkid", params);
}
export async function getUserUserList(KigyoCd) {
  const params = {
    KigyoCd: KigyoCd,
  };
  return await getRequest("/m_user_get/user_list", params);
}
export async function getUserAllUser(KigyoCd) {
  const params = {
    KigyoCd: KigyoCd,
  };
  return await getRequest("/m_user_get/all_user", params);
}
//----------------------------------------------------------------
// 事業所管理者取得
//----------------------------------------------------------------
export async function getJigyoAdminList(KigyoCd) {
  const params = {
    KigyoCd: KigyoCd,
  };
  return await getRequest("/m_user_get/jigyo_admin_list", params);
}
export async function postUserInsert(KigyoCd) {
  const params = new URLSearchParams();
  params.append("KigyoCd", KigyoCd);
  return await postRequest("/m_user_post/insert", params);
}
export async function postUserUpdate(KigyoCd) {
  const params = new URLSearchParams();
  params.append("KigyoCd", KigyoCd);
  return await postRequest("/m_user_post/update", params);
}
export async function postUserDelete(KigyoCd) {
  const params = new URLSearchParams();
  params.append("KigyoCd", KigyoCd);
  return await postRequest("/m_user_post/delete", params);
}
export async function postUserInsertCsv(KigyoCd) {
  const params = new URLSearchParams();
  params.append("KigyoCd", KigyoCd);
  return await postRequest("/m_user_post/insert_csv", params);
}
//----------------------------------------------------------------
// 事業所管理者登録
//----------------------------------------------------------------
export async function postJigyoAdminInsert(KigyoCd, UserCd, NewPassword) {
  const params = new URLSearchParams();
  params.append("KigyoCd", KigyoCd);
  params.append("UserCd", UserCd);
  params.append("NewPassword", NewPassword);
  return await postRequest("/m_user_post/jigyo_admin_update", params);
}
//----------------------------------------------------------------
// 事業所管理者取得
//----------------------------------------------------------------
export async function postJigyoAdminUpdate(KigyoCd, UserCd, NewPassword) {
  const params = new URLSearchParams();
  params.append("KigyoCd", KigyoCd);
  params.append("UserCd", UserCd);
  params.append("NewPassword", NewPassword);
  params.append("sUser", sUser);
  params.append("sAccess", sAccess);
  return await postRequest("/m_user_post/jigyo_admin_update", params);
}

//****************************************************************
// カレンダーテーブル
//****************************************************************
//----------------------------------------------------------------
// 予定一覧取得
//----------------------------------------------------------------
export async function getCalendarList(KigyoCd, Year) {
  const params = {
    KigyoCd: KigyoCd,
    Year: Year,
  };
  return await getRequest("/t_calendar_get/list", params);
}
//----------------------------------------------------------------
// 予定登録
//----------------------------------------------------------------
export async function postCalendarInsert(KigyoCd, Title, DateSt, DateEd) {
  const params = new URLSearchParams();
  params.append("KigyoCd", KigyoCd);
  params.append("Title", Title);
  params.append("DateSt", DateSt);
  params.append("DateEd", DateEd);
  params.append("sUser", sUser);
  return await postRequest("/t_calendar_post/insert", params);
}
//----------------------------------------------------------------
// 予定更新
//----------------------------------------------------------------
export async function postCalendarUpdate(Title, DateSt, DateEd, tKigyoCd, tSeqNo) {
  const params = new URLSearchParams();
  params.append("Title", Title);
  params.append("DateSt", DateSt);
  params.append("DateEd", DateEd);
  params.append("sUser", sUser);
  params.append("tKigyoCd", tKigyoCd);
  params.append("tSeqNo", tSeqNo);
  return await postRequest("/t_calendar_post/update", params);
}
//----------------------------------------------------------------
// 予定削除
//----------------------------------------------------------------
export async function postCalendarDelete(tKigyoCd, tSeqNo) {
  const params = new URLSearchParams();
  params.append("sUser", sUser);
  params.append("tKigyoCd", tKigyoCd);
  params.append("tSeqNo", tSeqNo);
  return await postRequest("/t_calendar_post/delete", params);
}

//****************************************************************
// 注文状況テーブル
//****************************************************************
export async function getChuJokyoOrderFax(KigyoCd) {
  const params = {
    KigyoCd: KigyoCd,
  };
  return await getRequest("/t_chu_jokyo_get/order_fax", params);
}
export async function getChuJokyoCount(KigyoCd) {
  const params = {
    KigyoCd: KigyoCd,
  };
  return await getRequest("/t_chu_jokyo_get/jokyo_count", params);
}
export async function getChuJokyoList(KigyoCd) {
  const params = {
    KigyoCd: KigyoCd,
  };
  return await getRequest("/t_chu_jokyo_get/jokyo_list", params);
}
export async function postChuJokyoUpdate(KigyoCd) {
  const params = new URLSearchParams();
  params.append("KigyoCd", KigyoCd);
  return await postRequest("/t_chu_jokyo_post/jokyo_update", params);
}

//****************************************************************
// 注文予約テーブル
//****************************************************************
export async function getChuYoyakuMonthly(KigyoCd) {
  const params = {
    KigyoCd: KigyoCd,
  };
  return await getRequest("/t_chu_yoyaku_get/monthly", params);
}
export async function getChuYoyakuPersonal(KigyoCd) {
  const params = {
    KigyoCd: KigyoCd,
  };
  return await getRequest("/t_chu_yoyaku_get/personal", params);
}
export async function getChuYoyakuListMonthly(KigyoCd, UserCd, Chumonbi) {
  const params = {
    KigyoCd: KigyoCd,
    UserCd: UserCd,
    Chumonbi: Chumonbi,
  };
  return await getRequest("/t_chu_yoyaku_get/yoyaku_list_monthly", params);
}
export async function getChuYoyakuListDaily(KigyoCd, UserCd, Chumonbi) {
  const params = {
    KigyoCd: KigyoCd,
    UserCd: UserCd,
    Chumonbi: Chumonbi,
  };
  return await getRequest("/t_chu_yoyaku_get/yoyaku_list_daily", params);
}
export async function getChuYoyakuInfo(KigyoCd) {
  const params = {
    KigyoCd: KigyoCd,
  };
  return await getRequest("/t_chu_yoyaku_get/yoyaku_info", params);
}
export async function getChuYoyakuMonthlyPrice(KigyoCd, UserCd, Simebi) {
  const params = {
    KigyoCd: KigyoCd,
    UserCd: UserCd,
    Simebi: Simebi,
  };
  return await getRequest("/t_chu_yoyaku_get/monthly_price", params);
}
export async function getChuYoyakuReserveList(KigyoCd) {
  const params = {
    KigyoCd: KigyoCd,
  };
  return await getRequest("/t_chu_yoyaku_get/reserve_list", params);
}

export async function getChuYoyakuListGyoshaM(KigyoCd) {
  const params = {
    KigyoCd: KigyoCd,
  };
  return await getRequest("/t_chu_yoyaku_get/yoyaku_list_gyosha_m", params);
}
export async function getChuYoyakuListGyoshaD(KigyoCd) {
  const params = {
    KigyoCd: KigyoCd,
  };
  return await getRequest("/t_chu_yoyaku_get/yoyaku_list_gyosha_d", params);
}
export async function getChuYoyakuGroup(KigyoCd) {
  const params = {
    KigyoCd: KigyoCd,
  };
  return await getRequest("/t_chu_yoyaku_get/group", params);
}
export async function getChuYoyakuListBentoD(KigyoCd) {
  const params = {
    KigyoCd: KigyoCd,
  };
  return await getRequest("/t_chu_yoyaku_get/yoyaku_list_bento_d", params);
}
export async function getChuYoyakuLast(KigyoCd) {
  const params = {
    KigyoCd: KigyoCd,
  };
  return await getRequest("/t_chu_yoyaku_get/yoyaku_last", params);
}
export async function postChuYoyakuInsert(KigyoCd, JigyoNo, UserCd, Chu_st, Chu_ed, Chu_week, GyoshaNo, BentoNo, Kingaku, Kosuu, Goukei) {
  const params = new URLSearchParams();
  params.append("KigyoCd", KigyoCd);
  params.append("JigyoNo", JigyoNo);
  params.append("UserCd", UserCd);
  params.append("Chu_st", Chu_st);
  params.append("Chu_ed", Chu_ed);
  params.append("Chu_week", Chu_week);
  params.append("GyoshaNo", GyoshaNo);
  params.append("BentoNo", BentoNo);
  params.append("Kingaku", Kingaku);
  params.append("Kosuu", Kosuu);
  params.append("Goukei", Goukei);
  params.append("sUser", sUser);
  return await postRequest("/t_chu_yoyaku_post/insert", params);
}
export async function postChuYoyakuUpdate(KigyoCd) {
  const params = new URLSearchParams();
  params.append("KigyoCd", KigyoCd);
  return await postRequest("/t_chu_yoyaku_post/update", params);
}
export async function postChuYoyakuDelete(tKigyoCd, tJigyoNo, tUserCd, tChumonbi, tSeqNo) {
  const params = new URLSearchParams();
  params.append("sUser", sUser);
  params.append("sAccess", sAccess);
  params.append("tKigyoCd", tKigyoCd);
  params.append("tJigyoNo", tJigyoNo);
  params.append("tUserCd", tUserCd);
  params.append("tChumonbi", tChumonbi);
  params.append("tSeqNo", tSeqNo);
  return await postRequest("/t_chu_yoyaku_post/delete", params);
}

//****************************************************************
// ログイン履歴テーブル
//****************************************************************
export async function getLoginList(KigyoCd, DateSt, DateEd) {
  const params = {
    KigyoCd: KigyoCd,
    DateSt: DateSt,
    DateEd: DateEd,
  };
  return await getRequest("/t_login_get/list", params);
}
export async function postLoginInsert(KigyoCd) {
  const params = new URLSearchParams();
  params.append("KigyoCd", KigyoCd);
  return await postRequest("/t_login_post/insert", params);
}

//****************************************************************
// お知らせテーブル
//****************************************************************
//----------------------------------------------------------------
// お知らせ一覧取得（利用者画面用）
//----------------------------------------------------------------
export async function getNoticeList(KigyoCd, UserCd, tKigyoCd) {
  const params = {
    KigyoCd: KigyoCd,
    UserCd: UserCd,
    tKigyoCd: tKigyoCd,
  };
  return await getRequest("/t_notice_get/list", params);
}
//----------------------------------------------------------------
// お知らせ一覧取得（管理画面用）
//----------------------------------------------------------------
export async function getNoticeListManagement(KigyoCd) {
  const params = {
    KigyoCd: KigyoCd,
  };
  return await getRequest("/t_notice_get/list_management", params);
}
//----------------------------------------------------------------
// お知らせ登録
//----------------------------------------------------------------
export async function postNoticeInsert(KigyoCd, Title, Content, DateSt, DateEd, ImpFlg) {
  const params = new URLSearchParams();
  params.append("KigyoCd", KigyoCd);
  params.append("Title", Title);
  params.append("Content", Content);
  params.append("DateSt", DateSt);
  params.append("DateEd", DateEd);
  params.append("ImpFlg", ImpFlg);
  params.append("sUser", sUser);
  params.append("sAccess", sAccess);
  return await postRequest("/t_notice_post/insert", params);
}
//----------------------------------------------------------------
// お知らせ更新
//----------------------------------------------------------------
export async function postNoticeUpdate(Title, Content, DateSt, DateEd, ImpFlg, tKigyoCd, tNoticeNo) {
  const params = new URLSearchParams();
  params.append("Title", Title);
  params.append("Content", Content);
  params.append("DateSt", DateSt);
  params.append("DateEd", DateEd);
  params.append("ImpFlg", ImpFlg);
  params.append("sUser", sUser);
  params.append("tKigyoCd", tKigyoCd);
  params.append("tNoticeNo", tNoticeNo);
  return await postRequest("/t_notice_post/update", params);
}
//----------------------------------------------------------------
// お知らせ削除
//----------------------------------------------------------------
export async function postNoticeDelete(tKigyoCd, tNoticeNo) {
  const params = new URLSearchParams();
  params.append("sUser", sUser);
  params.append("tKigyoCd", tKigyoCd);
  params.append("tNoticeNo", tNoticeNo);
  return await postRequest("/t_notice_post/delete", params);
}
//----------------------------------------------------------------
// お知らせ既読更新
//----------------------------------------------------------------
export async function postNoticeRead(KigyoCd, NoticeNo, UserCd, NoticeKigyoCd) {
  const params = new URLSearchParams();
  params.append("KigyoCd", KigyoCd);
  params.append("NoticeNo", NoticeNo);
  params.append("UserCd", UserCd);
  params.append("sUser", sUser);
  params.append("sAccess", sAccess);
  params.append("NoticeKigyoCd", NoticeKigyoCd);
  return await postRequest("/t_notice_post/read", params);
}

/*--- 2024.06.28 MCS)ADACHI ADD STRT ---*/
// ****************************************
// 休日テーブル
// ****************************************
// 休日情報取得
export async function getHoliday(KigyoCd) {
  const params = {
    KigyoCd: KigyoCd,
  };
  return await getRequest("/m_holiday_get/holiday", params);
}

// 休日情報取得（３か月）
export async function getHolidayMonthly(KigyoCd, SelYMD) {
  const params = {
    KigyoCd: KigyoCd,
    SelYMD: SelYMD,
  };
  return await getRequest("/m_holiday_get/holiday_monthly", params);
}

// 休日情報登録
export async function postHolidayInsert(KigyoCd, Holiday, HolidayWeek, HolidayName, HolidayKbn, sUser, sAccess) {
  const params = {
    KigyoCd: KigyoCd,
    Holiday: Holiday,
    HolidayWeek: HolidayWeek,
    HolidayName: HolidayName,
    HolidayKbn: HolidayKbn,
    sUser: sUser,
    sAccess: sAccess,
  };
  return await postRequest("/m_holiday_post/save", params);
}

// 休日情報一括作成
export async function postHolidayBulkInsert(KigyoCd, SelYear, CheckItem, sUser, sAccess) {
  const params = {
    KigyoCd: KigyoCd,
    SelYear: SelYear,
    CheckItem: CheckItem,
    sUser: sUser,
    sAccess: sAccess,
  };
  return await postRequest("/m_holiday_post/bulkcreate", params);
}

// 休日情報更新
export async function postHolidayUpdate(KigyoCd, Holiday, HolidayWeek, HolidayName, HolidayKbn, sUser, sAccess, cbDate) {
  const params = {
    KigyoCd: KigyoCd,
    Holiday: Holiday,
    HolidayWeek: HolidayWeek,
    HolidayName: HolidayName,
    HolidayKbn: HolidayKbn,
    sUser: sUser,
    sAccess: sAccess,
    cbDate: cbDate,
  };
  return await postRequest("/m_holiday_post/update", params);
}

// 休日情報削除
export async function postHolidayDelete(KigyoCd, SelYear) {
  const params = {
    KigyoCd: KigyoCd,
    SelYear: SelYear,
  };
  return await postRequest("/m_holiday_post/delete", params);
}

// 休日一括情報削除
export async function postHolidayBulkDelete(KigyoCd, SelYear, CheckItem) {
  const params = {
    KigyoCd: KigyoCd,
    SelYear: SelYear,
    CheckItem: CheckItem,
  };
  return await postRequest("/m_holiday_post/bulkdelete", params);
}
/*--- 2024.06.28 MCS)ADACHI ADD END ---*/

/* -- 2024.08.22 MCS)KONNO ADD START --*/
// 休日情報取得（判定）
export async function getHolidayCheck(KigyoCd, Date) {
  const params = {
    KigyoCd: KigyoCd,
    Date: Date,
  };
  return await getRequest("/m_holiday_get/check", params);
}
/* -- 2024.08.22 MCS)KONNO ADD END --*/

/*--- 2024.07.09 MCS)KONNO ADD STRT ---*/
// ****************************************
// 祝日テーブル
// ****************************************
// 祝日情報取得（3か月）
export async function getJapanHolidayMonthly(YearMonth) {
  const params = {
    YearMonth: YearMonth,
  };
  return await getRequest("/m_japan_holiday_get/list_monthly", params);
}
/*--- 2024.07.09 MCS)KONNO ADD END ---*/
