<template>
  <v-btn 
    @click="onClick()" 
    :color="color" 
    :block="block" 
    :loading="loading" 
    :width="width != '' ? width : ''" 
    dark 
    elevation="1"
    >{{ label }}
  </v-btn>
</template>

<script>
export default {
  name: "BaseBtn",
  props: {
    label: {
      type: String,
      default: "",
      required: true,
    },
    color: {
      type: String,
      default: "teal",
      required: false,
    },
    width: {
      type: String,
      default: "",
      required: false,
    },
    block: {
      type: Boolean,
      default: false,
      required: false,
    },
    loading: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  methods: {
    onClick() {
      this.$emit("click");
    },
  },
};
</script>
