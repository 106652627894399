<template>
  <base-dialog v-model="dialog" :title="title" :close="false" color="grey darken-3" width="480px">
    <template v-slot:body>
      <v-sheet class="pa-4 text--primary" style="white-space: pre-wrap">
        {{ message }}
      </v-sheet>
    </template>
    <template v-slot:footer>
      <base-btn label="はい" color="primary" width="96px" @click="onClickAgree()" />
      <base-btn label="いいえ" color="blue-grey" width="96px" @click="onClickCansel()" />
    </template>
  </base-dialog>
</template>

<script>
import BaseBtn from "../bases/BaseBtn.vue";
import BaseDialog from "../bases/BaseDialog.vue";

export default {
  data: () => ({
    dialog: false,
    resolve: null,
    reject: null,
    message: null,
    title: "確認",
  }),
  components: {
    BaseBtn,
    BaseDialog,
  },
  methods: {
    openDialog(option) {
      this.title = option.title != null ? option.title : "確認";
      this.message = option.message != null ? option.message : "確認メッセージ";
      this.btnYes = option.btnYes != null ? option.btnYes : "はい";
      this.btnNo = option.btnNo != null ? option.btnNo : "いいえ";
      this.dialog = true;
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    onClickAgree() {
      this.resolve(true);
      this.dialog = false;
    },
    onClickCansel() {
      this.resolve(false);
      this.dialog = false;
    },
  },
};
</script>
