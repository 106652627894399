<!--------------------------------------------------
  システム名：弁当注文ビフォサック
  プログラム名：SystemBar.vue
  モジュール名：システムバー
  備考：
-------------------------------------------------->
<template>
  <v-system-bar app>
    <span> V{{ $version }} </span>
    <v-spacer></v-spacer>
    <span>
      <strong>{{ nowTime }}</strong>
    </span>
  </v-system-bar>
</template>

<script>
import moment from "moment";

export default {
  name: "SystemBar",
  data: () => ({
    nowTime: "",
  }),
  methods: {
    timeFormate(timeStamp) {
      let nowYear = moment(timeStamp).format("YYYY");
      let nowMonth = moment(timeStamp).format("MM");
      let nowDay = moment(timeStamp).format("DD");
      let nowHour = moment(timeStamp).format("HH");
      let nowMin = moment(timeStamp).format("mm");
      let nowSec = moment(timeStamp).format("ss");

      // システムバー時刻
      this.nowTime = nowYear + "/" + nowMonth + "/" + nowDay + " " + nowHour + ":" + nowMin + ":" + nowSec;

      // ストア時刻
      this.$store.dispatch("date", nowYear + nowMonth + nowDay);
      this.$store.dispatch("time", nowHour + nowMin + nowSec);
    },
    nowTimes() {
      this.timeFormate(new Date());
    },
  },
  created() {
    setInterval(this.nowTimes, 1000);
  },
};
</script>
