<template>
  <v-dialog :value="value" :width="width" persistent no-click-animation>
    <v-card>
      <v-sheet :color="color" style="background: linear-gradient(90deg, rgba(0, 0, 0, 0), rgba(255, 255, 255, 0.6))">
        <v-card-title class="px-4 py-2">
          <h3 style="color: white">{{ title }}</h3>
          <v-spacer></v-spacer>
          <slot name="header"></slot>
        </v-card-title>
      </v-sheet>
      <v-divider></v-divider>
      <v-sheet class="overflow-y-auto" max-height="calc(80dvh - 100px)">
        <slot name="body"></slot>
      </v-sheet>
      <v-divider></v-divider>
      <v-sheet color="grey lighten-3">
        <v-card-actions>
          <v-spacer></v-spacer>
          <slot name="footer"></slot>
          <base-btn v-if="close" label="閉じる" color="blue-grey" width="96px" @click="onClickClose()"></base-btn>
        </v-card-actions>
      </v-sheet>
      <!-- ローディング表示 -->
      <base-progress-circular :value="loading" />
    </v-card>
  </v-dialog>
</template>

<script>
import BaseBtn from "./BaseBtn.vue";
import BaseProgressCircular from "./BaseProgressCircular.vue";

export default {
  name: "BaseDialog",
  props: {
    // タイトル
    title: {
      type: String,
      default: "",
      required: true,
    },
    // 表示切り替え（v-modelでアクセスしてください）
    value: {
      type: Boolean,
      default: false,
      required: true,
    },
    // 横幅
    width: {
      type: String,
      default: "960px",
      required: false,
    },
    // 横幅
    color: {
      type: String,
      default: "grey darken-1",
      required: false,
    },
    // ローディング
    loading: {
      type: Boolean,
      default: false,
      required: false,
    },
    // 閉じるボタン
    close: {
      type: Boolean,
      default: true,
      required: false,
    },
  },
  components: {
    BaseBtn,
    BaseProgressCircular,
  },
  methods: {
    onClickClose() {
      this.$emit("click-close");
    },
  },
};
</script>
