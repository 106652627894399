// プラン
export const plan = [
  { value: 10, text: "10プラン" },
  { value: 50, text: "50プラン" },
  { value: 100, text: "100プラン" },
  { value: 150, text: "150プラン" },
  { value: 200, text: "200プラン" },
  { value: 250, text: "250プラン" },
  { value: 300, text: "300プラン" },
];
// 支払区分
export const paykbn = [
  { value: 0, text: "現金" },
  { value: 1, text: "給与控除" },
];
