import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

const getDefaultState = () => {
  return {
    isLogin: false, // ログイン状態
    nowDate: "00000000", // システム年月日
    nowTime: "000000", // システム時刻
    kigyoCode: "", // 企業コード
    kigyoName: "", // 企業名
    kigyoPlan: "0", // プラン
    jigyoNo: "0", // 事業所No
    jigyoName: "", // 事業所名
    bushoNo: "0", // 所属部署No
    bushoName: "", // 所属部署名
    userCode: "", // 利用者コード
    userName: "", // 氏名
    adminAuth: "0", // 管理者権限
    jigyoAuth: "0", // 事業所管理者権限
  };
};

const store = new Vuex.Store({
  state: getDefaultState(),
  mutations: {
    access(state, kigyo) {
      state.kigyoCode = kigyo.str_kigyo_cd;
      state.kigyoName = kigyo.str_kigyo_name;
      state.kigyoPlan = kigyo.num_plan;
    },
    login(state, user) {
      state.isLogin = true;
      state.jigyoNo = user.num_jigyo_no;
      state.jigyoName = user.str_jigyo_name;
      state.bushoNo = user.num_busho_no;
      state.bushoName = user.str_busho_name;
      state.userCode = user.str_user_cd;
      state.userName = user.str_user_name;
      state.adminAuth = user.num_kanri_flg;
      state.jigyoAuth = user.num_jigyo_kanri;
    },
    date(state, date) {
      state.nowDate = date;
    },
    time(state, time) {
      state.nowTime = time;
    },
    reset(state) {
      Object.assign(state, getDefaultState());
    },
  },
  getters: {
    getDate: (state) => {
      return state.nowDate;
    },
    getTime: (state) => {
      return state.nowTime;
    },
    getLogin: (state) => {
      return state.isLogin;
    },
  },
  actions: {
    access(context, kigyo) {
      context.commit("access", kigyo);
    },
    login(context, user) {
      context.commit("login", user);
    },
    date(context, date) {
      context.commit("date", date);
    },
    time(context, time) {
      context.commit("time", time);
    },
  },
  plugins: [
    createPersistedState({
      // ストレージのキーを指定。デフォルトではvuex
      key: "befosc",
      // 管理対象のステートを指定
      // paths: [],
      // ストレージの種類を指定。デフォルトではローカルストレージ
      storage: window.sessionStorage,
    }),
  ],
});

export default store;
