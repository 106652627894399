<template>
  <v-overlay absolute :value="value" opacity="0.1">
    <v-progress-circular indeterminate color="primary"></v-progress-circular>
  </v-overlay>
</template>

<script>
export default {
  name: "BaseProgressCircular",
  props: {
    value: {
      type: Boolean,
      default: false,
      required: true,
    },
  },
};
</script>
