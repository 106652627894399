<!--------------------------------------------------
  システム名：弁当注文ビフォサック
  プログラム名：App.vue
  モジュール名：ルートコンポーネント
  備考：
-------------------------------------------------->
<template>
  <v-app>
    <!-- システムバー -->
    <base-system-bar />
    <!-- スナックバー -->
    <base-snack-bar ref="snackbar" />
    <!-- 確認ダイアログ -->
    <confirm-dialog ref="confirm" />
    <!-- 画面コンポーネント -->
    <router-view />
  </v-app>
</template>

<script>
import BaseSystemBar from "./components/bases/BaseSystemBar";
import BaseSnackBar from "./components/bases/BaseSnackBar";
import ConfirmDialog from "./components/templates/ConfirmDialog";

export default {
  components: {
    BaseSystemBar,
    BaseSnackBar,
    ConfirmDialog,
  },
  mounted() {
    this.$root.confirm = this.$refs.confirm;
    this.$root.snackbar = this.$refs.snackbar;
  },
};
</script>
